import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";

const Bar = ({
  data,
  groupMode,
  layout,
  colorScheme,
  colorList = [],
  indexBy,
  keys,
  legends,
  leftAxisFormat = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  return (
    <div style={{ height: "400px" }}>
      {/* <h1>Bar Chart</h1> */}
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 130, bottom: 75, left: 60 }}
        padding={0.31}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colorScheme ? { scheme: colorScheme } : colorList} //{{ scheme: colorScheme }}
        groupMode={groupMode}
        layout={layout}
        labelFormat={(d) => <tspan x={50}>{d}</tspan>}
        label={(d) => {
          return `${d.value}`;
        }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: colors.grey[100],
              },
              text: {
                fill: colors.grey[100],
              },
            },
            legend: {
              text: {
                fontSize: 12,
                fill: colors.grey[100],
              },
            },

          },
          tooltip: {
            container: {
              background: colors.grey[800],
              color: "inherit",
              fontSize: "inherit",
              borderRadius: "2px",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
              padding: "5px 9px",
            },
            basic: {
              whiteSpace: "pre",
              display: "flex",
              alignItems: "center",
            },
            table: {},
            tableCell: {
              padding: "3px 5px",
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "rtsFees",
            },
            id: "dots",
          },
          {
            match: {
              id: "psFees",
            },
            id: "dots",
          },
          {
            match: {
              id: "rtsTax",
            },
            id: "lines",
          },
          {
            match: {
              id: "psTax",
            },
            id: "lines",
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45,
          legend: legends.buttom,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legends.left,
          legendPosition: "middle",
          legendOffset: -40,
          color: "red",
          format: leftAxisFormat,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={colors.grey[100]}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        // tooltip={(input) => {
        //   console.log(input);
        //   return <div>hello</div>;
        // }}
        barAriaLabel={function (e) {
          return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
        }}
      />
    </div>
  );
};

export default Bar;
