import React from "react";
// import {mockLinechartData as data} from '../data/mockData'
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const Line = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div style={{ height: "500px" }} className="chart">
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        colors={[
          "rgb(128,0,0)",
          "rgb(16,30,171)",
          "rgb(0, 172, 193)",
          "rgb(156, 39, 176)",
          "rgb(6,110,171)",
          "rgb(191, 191, 3)",
          "rgb(0, 102, 102)",
          "rgb(153, 0, 153)",
          " rgb(143, 97, 226)",
          "rgb(128,128,0)",
          "rgb(0,128,128)",
          "rgb(128,128,128)",
          "rgb(40,40,105)",
          "rgb(0,128,0)",
          "rgb(0,255,255)",
          "rgb(255,0,0)"
        ]}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: "Time series",
          legendOffset: 40,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        theme={{
          textColor: "white",
          axis: {
            ticks: {
              line: {
                stroke: colors.grey[100],
              },
              text: {
                fill: colors.grey[100],
              },
            },
            legend: {
              text: {
                fontSize: 12,
                fill: colors.grey[100],
              },
            },
          },
          tooltip: {
            container: {
              background: colors.grey[800],
              color: "inherit",
              fontSize: "inherit",
              borderRadius: "2px",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
              padding: "5px 9px",
            },
            basic: {
              whiteSpace: "pre",
              display: "flex",
              alignItems: "center",
            },
            table: {},
            tableCell: {
              padding: "3px 5px",
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
        pointSize={10}
        curve="cardinal"
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(255, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        enableGridX={false}
        enableGridY={false}
        enablePointLabel={true}
      />
    </div>
  );
};

export default Line;
