import { Box, Button,  Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../Api";
import Bar from "../../components/Bar";
import Line from "../../components/Line";
import { tokens } from "../../theme";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [trendingData, setTrendingData] = useState([]);
  const [orgBarData, setOrgBarData] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [subProjectsBarData, setSubProjectsBarData] = useState([]);
  const [stackedChartData, setStackedChartData] = useState([]);

  const orgName = "RTS";

  useEffect(() => {
    console.log("useEffect called from dashboard");
    getSubProjects();
    getOrgs();
  }, []);

  const getOrgs = async () => {
    try {
      // const r = await Api.get(`/orgs/RTS`);
      // console.log(r.data);

      const dash = await Api.get(`/dashboard/${orgName}`);
      // console.log(dash.data);
      const projects = Object.keys(dash.data.lastSnap.SnapData);
      const trends = [];
      const orgBar = [];
      const colors = [];
      const countMap = {};
      for (let snap of dash.data.snaps) {
        for (let project of projects) {
          if (!countMap[project]) {
            countMap[project] = [];
          }
          countMap[project].push({
            x: snap.SK.replace("SNAP#", ""),
            y: snap.SnapData[project],
          });
        }
      }

      for (let project of projects) {
        const c = `hsl(${parseInt(Math.random() * 255)}, 70%, 50%)`;
        colors.push(c);
        trends.push({
          id: project,

          data: countMap[project],
        });

        orgBar.push({
          project: project,
          count: dash.data.lastSnap.SnapData[project],
          countColor: c,
        });
      }
      setTrendingData(trends);
      setOrgBarData(orgBar);
      setColorList(colors);
    } catch (error) {
      console.log(error);
    }
  };
  const roundToDecimal = (value, precision) => {
    return (
      Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
    );
  };

  const getSubProjects = async () => {
    try {
      const dash = await Api.get(`/toggles/RTS`);
      const { projects, projectTogglesMap } = dash.data;

      const subProjectsMap = {};
      for (const project of projects) {
        const projectName = project.ProjectName.replace(/\s+/g, "");
        const projectToggles =
          projectTogglesMap[project.ProjectName.replace(/\s+/g, "")];
        const subproject = project.SubProjects;
        projectTogglesMap[projectName] = projectToggles;
        subProjectsMap[projectName] = subproject;
      }

      const projectToDrill = "RegUSA";
      createSubProjectBar(
        subProjectsMap[projectToDrill],
        projectTogglesMap[projectToDrill][0].ToggleData
      );

      createStackedChartData(projects, projectTogglesMap);
      createStackedChartData(projects, projectTogglesMap);
    } catch (error) {
      console.log(error);
    }
  };

  const createSubProjectBar = (subProjects, projectToggles) => {
    let untagged = 0;
    const counterMap = { UNTAGGED: 0 };
    for (let toggle of projectToggles) {
      const allTags = toggle["tags"].map((el) => el.toLowerCase()) || [];
      // console.log("All tags for the toggle ", toggle.name, allTags);

      let isTagged = false;

      for (let subProject of subProjects) {
        if (allTags.includes(subProject.toLowerCase())) {
          isTagged = true;
          if (!counterMap[subProject]) {
            counterMap[subProject] = 0;
          }

          counterMap[subProject] = counterMap[subProject] + 1;
        }
      }

      if (isTagged === false) {
        counterMap["UNTAGGED"] = counterMap["UNTAGGED"] + 1;
      }
    }

    let labels = Object.keys(counterMap);
    let subProjectsArray = [];

    for (const label of labels) {
      subProjectsArray.push({
        project: label,
        count: counterMap[label],
        countColor: "hsl(135, 70%, 50%)",
      });
    }

    setSubProjectsBarData(subProjectsArray);
  };

  // Function takes two parameters, projectItems and projectTogglesMap, and creates a stacked chart data object for visualization.
  const createStackedChartData = async (projects, projectTogglesMap) => {
    const ageGroups = ["< 180", "180 - 359", "360 - 719", "720+"];
    const stackedData = [];
    const projectsNames = [];
    for (const ageGroup of ageGroups) {
      for (const project of projects) {
        const projectName = project.ProjectName.replace(/\s+/g, "");
        projectsNames.push(projectName);
        const projectToggles = projectTogglesMap[projectName];
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;
        let count4 = 0;

        if (projectToggles && projectToggles.length > 0) {
          for (const toggle of projectToggles[0].ToggleData) {
            const age = toggle.ageInDays;
            if (ageGroup === "< 180" && age < 180) {
              count1++;
            }
            if (ageGroup === "180 - 359" && age >= 180 && age < 360) {
              count2++;
            }
            if (ageGroup === "360 - 719" && age >= 360 && age < 720) {
              count3++;
            }
            if (ageGroup === "720+" && age >= 720) {
              count4++;
            }
          }
        }

        stackedData.push({
          project: projectName,
          "< 180": count1,
          count1Color: "rgb(6,110,171)",
          "180 - 359": count2,
          count2Color: "rgb(106,10,171)",
          "360 - 719": count3,
          count3Color: "rgb(16,30,171)",
          "720+": count4,
          count4Color: "rgb(65,90,171)",
        });

        stackedData?.forEach((el) => {
          if (el.project === projectName) {
            if (count1 !== 0) {
              el["< 180"] = count1;
            }
            if (count2 !== 0) {
              el["180 - 359"] = count2;
            }
            if (count3 !== 0) {
              el["360 - 719"] = count3;
            }
            if (count4 !== 0) {
              el["720+"] = count4;
            }
          }
        });
      }
    }
    const uniqueArray = projectsNames?.filter(
      (el, index, array) => array.indexOf(el) === index
    );
    const uniqueStackedData = stackedData.slice(0, uniqueArray.length);

    setStackedChartData(uniqueStackedData);
    return uniqueStackedData;
  };

  return (
    <Box m="10px">
      <Typography
        variant="h3"
        align="center"
        color={colors.grey[200]}
        scope="row"
      >
        Darkly Insight Dashboard
      </Typography>
      {/* <Button variant="contained" color="success" onClick={async () => await getOrgs()}>
        Get Orgs
      </Button> */}
      <Line data={trendingData}></Line>
      <Bar
        data={orgBarData}
        groupMode={"grouped"}
        layout={"vertical"}
        indexBy={"project"}
        keys={["count"]}
        //colorScheme="accent"
        colorList={["rgb(6,110,171)"]}
        legends={{ left: "No of Toggles", buttom: "" }}
        leftAxisFormat={(d) => `${d}`}
      ></Bar>
      <Bar
        data={subProjectsBarData}
        groupMode={"grouped"}
        layout={"vertical"}
        indexBy={"project"}
        keys={["count"]}
        colorList={["rgb(143, 97, 226)"]}
        legends={{ left: "Tag Count for Subprojects of RegUSA", buttom: "" }}
        leftAxisFormat={(d) => `${d}`}
      ></Bar>
      <Bar
        data={stackedChartData}
        groupMode={"stacked"}
        layout={"vertical"}
        indexBy={"project"}
        keys={["< 180", "180 - 359", "360 - 719", "720+"]}
        //colorScheme="accent"
        colorList={[
          "rgb(156, 39, 176)",
          "rgb(16,30,171)",
          "rgb(0, 172, 193)",
          "rgb(6,110,171)",
        ]}
        legends={{ left: "Toggles By Age Distribution", buttom: "" }}
        leftAxisFormat={(d) => `${d}`}
      ></Bar>
    </Box>
  );
};

export default Dashboard;
