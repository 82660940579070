import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  {
    field: "id",
    headerName: "No.",
    flex: 0.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "role",
    headerName: "Role",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastSeen",
    headerName: "Last Seen",
    flex: 1.2,
    headerAlign: "center",
    align: "center",
  },
];

const UsersTable = (usersInfo) => {
  const rows = [];
  usersInfo.data.forEach(
    ({ PK, UserName, UserRole, UserRoles, Timestamp}, index) => {
      const getLastSeenDate = (time) => {
        return time? new Date(time).toLocaleString():""
      };
      const getEmail = (email) => {
        return email.replace("USER#", "").toLowerCase()
      };
      
      rows.push({
        id: index + 1,
        name: UserName.replace(".", " "),
        email: getEmail(PK),
        role: UserRole ? UserRole : UserRoles[0],
        lastSeen: getLastSeenDate(Timestamp)

      });
    }
  );
  return (
    <Box sx={{ fontSize: "36px", height: "400px", width: "100%" }}>
      <DataGrid
      autoHeight
        sx={{ fontSize: 17 }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disablecheckboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
};
export default UsersTable;
