export default {
  oidc: {
    issuer: "https://coxauto.okta.com",
    clientId: "0oa1t0331upgBFYYN0h8",
    scopes: ["openid", "profile", "email"],
    redirectUri: `${window.location.origin}/login/callback`,
    disableHttpsCheck: false,
    useInteractionCodeFlow: false,
    useClassicEngine: true,
  },
};

// issuer: "https://atg.oktapreview.com",
// clientId: "0oa1sbjkikuI1mfav0h8",

// issuer: "https://dev-81427545.okta.com/oauth2/default",
// clientId: "0oaaineevbzt7GGDf5d7",
