import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Api from "../../Api";
import TogglesTable from "../../components/TogglesTable";
import { nanoid } from "nanoid";
import useLocalStorage from "../../utils/customHooks";
import Spinner from "../../components/Spinner";
import Filter from "../../components/Filter";

const Toggles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [toggleInfo, setToggleInfo] = useLocalStorage("toggleInfo", null);

  const [filterPrj, setFilterPrj] = useState(null);
  const [filter, setFilter] = useState("");

  const changeFilter = (e) => {
    setFilter(e.currentTarget.value);
  };
  useEffect(() => {
    if (!toggleInfo) {
      getToggles();
    } else {
      setUpFilteredProjects();
    }
  }, [filter]);

  const getToggles = async () => {
    try {
      const dash = await Api.get(`/toggles/RTS`);
      const { projects, projectTogglesMap } = dash.data;
      const projectsNames = [];
      for (const project of projects) {
        const projectToggles =
          projectTogglesMap[project.ProjectName.replace(/\s+/g, "")];
        projectToggles.sort(
          (a, b) => a.ToggleData.ageInDays - b.ToggleData.ageInDays
        );
        projectsNames.push(project.ProjectName.replace(/\s+/g, ""));
      }
      await setToggleInfo(projectTogglesMap);

      await setFilterPrj(projectsNames);
    } catch (error) {
      console.log(error);
    }
  };

  const setUpFilteredProjects = async () => {
    try {
      await setFilterPrj(Object.keys(toggleInfo));
      const filteredProjects = await Object.keys(toggleInfo).filter((prj) =>
        prj.toLowerCase().includes(filter.toLocaleLowerCase())
      );
      await setFilterPrj(filteredProjects);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box m="10px">
      <Typography
        variant="h3"
        align="center"
        color={colors.grey[200]}
        scope="row"
      >
        Toggles By Projects
      </Typography>

      <Filter filter={filter} onChangeFilter={changeFilter} />
      {toggleInfo ? (
        filterPrj &&
        filterPrj.map((project) => {
          const [toggleData] = toggleInfo[project];
          if (!toggleData) {
            return;
          } else {
            return (
              <Box key={nanoid()}>
                <h2>{project}</h2>
                <TogglesTable data={toggleData} />
              </Box>
            );
          }
        })
      ) : (
        <Spinner />
      )}
    </Box>
  );
};

export default Toggles;
