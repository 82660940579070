import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import UsersTable from "../../components/UsersTable";
import { nanoid } from "nanoid";
import useLocalStorage from "../../utils/customHooks";
import Spinner from "../../components/Spinner";
import Api from "../../Api";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usersInfo, setUsersInfo] = useLocalStorage("usersInfo", null);
  const orgName = "RTS";

  const getAllUsers = async (orgKey) => {
    try {
      const r = await Api.get(`/all-users/${orgKey}`);
      console.log("ALL-USERS", r.data);
      if (!r.data.length) return;
      setUsersInfo(r.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!usersInfo) {
      getAllUsers(orgName);
      return;
    }
  }, []);

  return (
    <Box m="10px">
      {usersInfo ? (
        <Box key={nanoid()}>
          <Typography
            variant="h3"
            align="center"
            marginBottom={3}
            color={colors.grey[200]}
            scope="row"
          >
            List of Users ({usersInfo.length})
          </Typography>
          <UsersTable data={usersInfo} />
        </Box>
      ) : (
        <Spinner />
      )}
    </Box>
  );
};

export default Users;
