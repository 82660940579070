import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const Notifications = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {}, []);
  return (
    <Box m="10px">
      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        View your notifications
      </Typography>
    </Box>
  );
};

export default Notifications;
