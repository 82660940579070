import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import AppSidebar from "./scenes/global/AppSidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Toggles from "./scenes/toggles/Toggles";
import Login from "./scenes/login/Login";
import Dashboard from "./scenes/dashboard/Dashboard";
import config from "./config";

import { Security, LoginCallback } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";

import SecuredShell from "./scenes/shell/SecuredShell";
import Users from "./scenes/users/Users";
import Configurations from "./scenes/configurations/Configurations";
import Notifications from "./scenes/notifications/Notifications";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const navigate = useNavigate();

  const [theme, colorMode] = useMode();

  useEffect(() => {
    console.log("called useEffect in App.jsx", process.env.NODE_ENV);

    return () => {};
  }, []);

  const customAuthHandler = () => {
    console.log("inside customAuthHandler");
    navigate("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log("restoreOriginalUri", originalUri);
    navigate(toRelativeUrl(originalUri || "", window.location.origin));
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="secured" element={<SecuredShell />}>
              <Route path="" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="toggles" element={<Toggles />} />
              <Route path="users" element={<Users />} />
              <Route path="configurations" element={<Configurations />} />
              <Route path="notifications" element={<Notifications />} />
            </Route>

            <Route path="/login/callback" element={LoginCallback} />
          </Routes>
        </Security>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
