import React from "react";
import API from "../../Api";

import OktaSignInWidget from "./OktaSignInWidget";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";


const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const orgName = "RTS"

  const getUserInfo = async () => {
    try {
      const r = await API.get(`/userinfo/${orgName}`);
      localStorage.setItem("user-info", JSON.stringify(r.data));
    } catch (error) {
    } finally {
    }
  };

  const onSuccess = async (tokens) => {
    console.log("onSuccess", tokens);
    console.log("accessToken", tokens.accessToken.accessToken);
    localStorage.setItem("okta-token", tokens.accessToken.accessToken);
    API.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("okta-token");
    await getUserInfo();
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  console.log("Auth status ", authState);
  return authState.isAuthenticated ? (
    <Navigate to={{ pathname: "/secured/dashboard" }} />
  ) : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};

export default Login;
