import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import Api from "../../Api";
import NotificationSwitch from "../../components/NotificationSwitch";

const Configurations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [org, setOrg] = useState(null);
  const [notifyEmail, setNotifyEmail] = useState(null);
  const [notifySlack, setNotifySlack] = useState(null);
  // const [usersEmails, setUsersEmails] = useState(null);
  const orgName = "RTS";

  useEffect(() => {
    if (!org) {
      getOrgConfig(orgName);
      // getAllUsers(orgName);
      return;
    }
    console.log("----------orgData--------", org);
    if (org.NotifyEmail !== notifyEmail) {
      console.log("called ---getUpdateNotifyEmail--- for the second time");
      getUpdateNotifyEmail(orgName, notifyEmail);
    }
    if (org.NotifySlack !== notifySlack) {
      console.log("called ---getUpdateNotifySlack--- for the second time");
      getUpdateNotifySlack(orgName, notifySlack);
    }
  }, [notifyEmail, notifySlack]);

  const getOrgConfig = async (orgKey) => {
    try {
      const r = await Api.get(`/orgs/${orgKey}`);
      setOrg(r.data);
      setNotifyEmail(r.data.NotifyEmail);
      setNotifySlack(r.data.NotifySlack);
    } catch (error) {
      console.log(error);
    }
  };

  // const getAllUsers = async (orgKey) => {
  //   try {
  //     const r = await Api.get(`/all-users/${orgKey}`);
  //     console.log("ALL-USERS", r.data);
  //     if (!r.data.length) return;
  //     const usersEmailsArray = [];

  //     for (const { PK } of r.data) {
  //       usersEmailsArray.push(PK.replace("USER#", "").toLowerCase());
  //     }

  //     setUsersEmails(usersEmailsArray.join(", "));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleEmailChange = () => {
    setNotifyEmail((prevState) => {
      return !prevState;
    });
  };

  const handleSlackChange = () => {
    setNotifySlack((prevState) => {
      return !prevState;
    });
  };

  const getUpdateNotifyEmail = async (orgKey, notifyEmail) => {
    try {
      const r = await Api.put(`/notifications/${orgKey}`, {
        NotifyEmail: notifyEmail,
      });
      setOrg(r.data.Attributes);
      setNotifyEmail(r.data.Attributes.NotifyEmail);
    } catch (error) {
      console.log(error);
    }
  };

  const getUpdateNotifySlack = async (orgKey, notifySlack) => {
    try {
      const r = await Api.put(`/slack-notifications/${orgKey}`, {
        NotifySlack: notifySlack,
      });
      setOrg(r.data.Attributes);
      setNotifySlack(r.data.Attributes.NotifySlack);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box m="10px">
      <Typography
        variant="h3"
        align="center"
        color={colors.grey[200]}
        scope="row"
      >
        Manage App Configurations
      </Typography>
      {org && (
        <Card variant="outlined" sx={{ mt: 5 }}>
          <CardContent>
            <Stack direction="row" spacing={2} marginBottom={1}>
              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                Organization:
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                {org.OrgName}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} marginBottom={1}>
              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                Recipient Emails:
              </Typography>

              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                {org.OrgRecipientEmails.join(", ")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} marginBottom={1}>
              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                Email Notifications:
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <NotificationSwitch
                  checked={notifyEmail}
                  onChange={handleEmailChange}
                  sx={{ m: 1 }}
                />
                <Typography>On</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="h6"
                align="center"
                color={colors.grey[200]}
                scope="row"
              >
                Slack Notifications:
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <NotificationSwitch
                  checked={notifySlack}
                  onChange={handleSlackChange}
                  sx={{ m: 1 }}
                />
                <Typography>On</Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Configurations;
