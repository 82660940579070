import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Spinner = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        color="secondary"
        style={{ margin: "auto", marginTop: "15%" }}
      />
    </Box>
  );
};

export default Spinner;
