import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  {
    field: "id",
    headerName: "No.",
    flex: 0.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cert",
    headerName: "Cert",
    flex: 0.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "test",
    headerName: "Test",
    flex: 0.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dev",
    headerName: "Dev",
    flex: 0.2,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "production",
    headerName: "Production",
    flex: 0.3,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ageInDays",
    headerName: "Age in Days",
    flex: 0.3,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastModified",
    headerName: "Last Modified",
    flex: 0.4,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "temporary",
    headerName: "Temporary",
    type: "boolean",
    flex: 0.3,
    headerAlign: "center",
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

const TogglesTable = (toggleInfo) => {
  const rows = [];

  toggleInfo.data.ToggleData.forEach(
    ({ name, environments, ageInDays, temporary, createdBy }, index) => {
      const getEnvironmentStatusEmoji = (status) => {
        return status ? "✅" : "❌";
      };
      const getCreatedByAppropriateStatus = (status) => {
        return status === "undefined" ? "no email address on file" : status;
      };
      // Function to calculate the age in days based on a given date
      const getAgeInDays = (date) => {
        return Math.floor((Date.now() - date) / (24 * 60 * 60 * 1000) - 1);
      };
      rows.push({
        id: index + 1,
        name,
        cert: getEnvironmentStatusEmoji(environments.cert.on),
        test: getEnvironmentStatusEmoji(environments.test.on),
        dev: getEnvironmentStatusEmoji(environments.dev.on),
        production: getEnvironmentStatusEmoji(environments.production.on),
        ageInDays: ageInDays,
        lastModified: getAgeInDays(environments.production.lastModified),
        temporary,
        createdBy: getCreatedByAppropriateStatus(createdBy),
      });
    }
  );

  return (
    <Box sx={{ fontSize: "36px", height: 700, width: "100%" }}>
      <DataGrid
        sx={{ fontSize: 17 }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disablecheckboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
};
export default TogglesTable;
