import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../theme";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Filter = ({ filter, onChangeFilter }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        width={250}
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Search by products: 'GAOLRS'"
          name="filter"
          value={filter}
          onChange={onChangeFilter}
        />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Filter;
