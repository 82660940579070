import React, { useEffect, useState } from "react";
import Topbar from "../global/Topbar";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import AppSidebar from "../global/AppSidebar";
import API from "../../Api";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";

const SecuredShell = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("useEffect called");
    if (!authState || !authState.isAuthenticated) {
      return;
    } else {
      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
      }
    }
  }, [oktaAuth]);

  console.log("main flow form secured shell");

  if (!authState || !authState?.isAuthenticated) {
    // return <div>Loading ... </div>;
    // navigate("/login");
    return <Navigate to={{ pathname: "/login" }} />;
  }

  API.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("okta-token");

  API.interceptors.request.use(
    (config) => {
      console.log("inceterceptor request");
      // config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      console.log("error in api call", error);
      if (error.response.status === 403) {
        // localStorage.removeItem("okta-token");
        // oktaAuth.signOut();
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <div className="app">
        <AppSidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default SecuredShell;
